<template>
  <div v-if="istrue" class="person p10 flex_column" style="overflow: hidden">
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh"
      pulling-text="Loading..."
      loosing-text="Loading..."
      loading-text="Loading..."
    >
      <div
        class="flex_start flex_items mt10"
        style="width: 100%"
        @click="goself"
      >
        <van-image
          v-if="info.avatar == null"
          fit="contain"
          :src="require('@/images/home/mtx.png')"
          class="mr10"
          width="60"
        ></van-image>
        <van-image
          v-else
          fit="contain"
          :src="info.avatar"
          class="mr10"
          width="60"
          round
        ></van-image>
        <div v-if="!see" class="flex_column">
          <span class="f17 c0 bold">{{ info.nickName }}</span>
          <div class="flex_start flex_items mt5">
            <span class="f20 c0 bold mr5 mt5" style="letter-spacing: 3px"
              >****</span
            >
            <van-image
              fit="contain"
              :src="require('@/images/home/nosee.png')"
              width="16"
              @click.stop="sees"
            ></van-image>
          </div>
        </div>
        <div v-else class="flex_column">
          <span class="f17 c0 bold">{{ info.nickName }}</span>
          <div class="flex_start flex_items mt5" style="color: #ff7900">
            <span class="f19 bold mr10 mt5">{{ info.balance }}&nbsp;PIX</span>
            <van-image
              fit="contain"
              :src="require('@/images/home/see.png')"
              width="16"
              height="16"
              @click.stop="sees"
            ></van-image>
          </div>
        </div>
      </div>
      <!-- t2 -->
      <div
        class="flex_around flex_items c0 mt20 pb20"
        style="border-bottom: 4px solid #f5f5f5"
      >
        <div class="flex_column flex_items">
          <span class="bold f15">{{ info.post_count }}</span>
          <span class="mt5 f13">Posts</span>
        </div>
        <div class="flex_column flex_items">
          <span class="bold f15">{{ info.following_count }}</span>
          <span class="mt5 f13">Following</span>
        </div>
        <div class="flex_column flex_items">
          <span class="bold f15">{{ info.follower_count }}</span>
          <span class="mt5 f13">Followers</span>
        </div>
        <div class="flex_column flex_items">
          <span class="bold f15">{{ info.collection_count }}</span>
          <span class="mt5 f13">Collections</span>
        </div>
      </div>
      <!-- t3 -->
      <div
        class="flex_start flex_items ptb20"
        style="overflow: hidden; border-bottom: 4px solid #f5f5f5"
      >
        <div
          style="width: 50%"
          class="bre9s flex_items flex_center"
          @click="gonext('wallet')"
        >
          <div class="flex_column flex_items">
            <van-image
              fit="contain"
              :src="require('@/images/home/mywallet.png')"
              class=""
              width="28"
            ></van-image>
            <p class="f14 c0 bold mt5 f-center">Token Wallet</p>
          </div>
        </div>
        <div
          style="width: 50%"
          class="flex_items flex_center"
          @click="gonext('mynft')"
        >
          <div class="flex_column flex_items">
            <van-image
              fit="contain"
              :src="require('@/images/home/mynft.png')"
              class=""
              width="28"
            ></van-image>
            <p class="f14 c0 bold mt5 f-center">NFT Wallet</p>
          </div>
        </div>
      </div>
      <!-- t4 -->
      <div class="flex_column">
        <a href="https://pixie.mobi/en/novice-guide/index.html">
          <div class="flex_start flex_items">
            <van-image
              fit="contain"
              :src="require('@/images/home/deng.png')"
              class="mr10"
              width="22"
              height="22"
            ></van-image>
            <div
              class="flex_between flex_items bbe9s ptb15 mt5"
              style="width: 85vw"
            >
              <p class="f15 c0">Pixie Highlights</p>
              <van-image
                fit="contain"
                :src="require('@/images/home/myright.png')"
                class="mr10"
                width="12"
              ></van-image>
            </div>
          </div>
        </a>
        <a href="https://pixie.mobi/en/convention/index.html">
          <div class="flex_start flex_items">
            <van-image
              fit="contain"
              :src="require('@/images/home/wen.png')"
              class="mr10"
              width="22"
              height="22"
            ></van-image>
            <div class="flex_between flex_items ptb15 mt5" style="width: 85vw">
              <p class="f15 c0">Pixie Convention</p>
              <van-image
                fit="contain"
                :src="require('@/images/home/myright.png')"
                class="mr10"
                width="12"
              ></van-image>
            </div>
          </div>
        </a>
      </div>
      <div style="height: 80px; width: 100%" class="bgwhite"></div>
    </van-pull-refresh>
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      see: false,
      isLoading: false,
      istrue:true,
      info: {},
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      Toast.loading({
        message: "Loading...",
        forbidClick: true,
      });
      this.util
        .get(this.HOST + "/account", {
          appVersion: "1.0",
          country: "cn",
          deviceName: "iPhone12",
          language: "en",
          osVersion: "14.7.1",
          platform: 2,
          timestamp: Date.parse(new Date()),
          timezone: "Asia/Shanghai",
        })
        .then((res) => {
          console.log(res);
          if (res.code == "000") {
            this.info = res.data.accountInfo;
          } else {
            Toast(res.msg);
          }
          this.istrue = true
          this.isLoading = false;
        });
    },
    goself() {
      this.$router.push({
        name: "selfwork",
        params: {
          author: localStorage.getItem("account"),
          from:"person"
        },
      });
    },
    onRefresh() {
      this.getData();
    },
    sees() {
      this.see = !this.see;
    },
    gonext(where) {
      let names = "";
      if (where == "wallet") {
        names = "wallet";
      }
      if (where == "mynft") {
        names = "mynft";
      }
      this.$router.push({
        name: names,
        params: {},
      });
    },
  },
  beforeCreate(to, from, next) {
    window.document.body.style.backgroundColor = "#fff";
  },
  beforeDestroy(to, from, next) {
    window.document.body.style.backgroundColor = "";
  },
};
</script>
<style>
</style>